import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ButtonGroup from '../../partials/ButtonGroup';
import KnowYourOptions from './KnowYourOptions';
import PaditScoreCalculator from './PaditScoreCalculator';

class Benefits extends Component {
    static url = '/consider/benefits';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleBack() {
        this.props.history.push(KnowYourOptions.url);
    }

    handleNext() {
        this.props.history.push(PaditScoreCalculator.url);
    }

    redStars = () => {
        let stars = [<br/>];

        for (let counter = 0; counter < 3; counter++) {
            stars.push(<i className="fas fa-star fa-red"></i>);
        }

        return stars;
    }

    createGrid = (red, total) => {
        let grid = [];

        for (let redCounter = 0; redCounter < red; redCounter++) {
            grid.push(<i className="fas fa-male fa-red"></i>);
        }

        for (let greyCounter = 0; greyCounter < (total - red); greyCounter++) {
            grid.push(<i className="fas fa-male"></i>);
        }

        return grid;
    }

    render() {
        return (
            <div className="benefits">
                <h1>Understand the Benefits of the Options</h1>

                <div className="row">
                    Implanting an ICD can also impact death, and these numbers will vary depending on whether you have <span className="strong">ischemic (ICM)
                    or non-ischemic cardiomyopathy (NICM)</span>. These 100 figures (&nbsp;<i className="fas fa-male"></i>&nbsp;) show a best estimate of what
                    happens to <span className="strong">100</span> people if they choose an ICD or continue with medication with regards to death and complications. Each
                    figure (&nbsp;<i className="fas fa-male"></i>&nbsp;) stands for one person. The red figures (&nbsp;<i className="fas fa-male fa-red"></i>&nbsp;)
                    show the number of people affected. These estimates of risk will vary based on your personal risk profile, sex, age, or other health problems (e.g.
                    kidney disease, lung disease, prior stroke, diabetes). Talk to your doctor about your individual risks.
                </div>
                <div className="grid-container">
                    <div className="row">
                        For patients with ischemic (ICM) cardiomyopathy:
                    </div>
                    <div className="grid-section">
                        <div className="grid-row grid-header grid-left">
                            Ischemic (ICM)
                        </div>
                        <div className="grid-row grid-header grid-middle">
                            ICD
                        </div>
                        <div className="grid-row grid-header grid-right">
                            No ICD
                        </div>
                        <div className="grid-row grid-row-1 grid-left">
                            Fewer patients who got an ICD died from <span className="strong">all-causes</span>.<sup>7</sup> {this.redStars()}
                        </div>
                        <div className="grid-row grid-row-1 grid-middle">
                            <div className="grid-people">{this.createGrid(22, 100)}</div>
                            <div className="grid-label"><span className="strong">22 out of 100 patients</span> who got an ICD died over 4 years.</div>
                        </div>
                        <div className="grid-row grid-row-1 grid-right">
                            <div className="grid-people">{this.createGrid(29, 100)}</div>
                            <div className="grid-label"><span className="strong">29 out of 100 patients</span> without an ICD died over 4 years.</div>
                        </div>
                        <div className="grid-row grid-row-2 grid-left">
                            Fewer patients who got an ICD died from <span className="strong">sudden cardiac causes i.e. arrhythmias.</span><sup>8</sup> {this.redStars()}
                        </div>
                        <div className="grid-row grid-row-2 grid-middle">
                            <div className="grid-people">{this.createGrid(4, 100)}</div>
                            <div className="grid-label"><span className="strong">4 out of 100 patients</span> who got an ICD died over 4 years.</div>
                        </div>
                        <div className="grid-row grid-row-2 grid-right">
                            <div className="grid-people">{this.createGrid(10, 100)}</div>
                            <div className="grid-label"><span className="strong">10 out of 100 patients</span> without an ICD died over 4 years.</div>
                        </div>
                    </div>
                    <div className="row">
                        For patients with non-ischemic (NICM) cardiomyopathy:
                    </div>
                    <div className="grid-section">
                        <div className="grid-row grid-header grid-left">
                            Non-ischemic (NICM)
                        </div>
                        <div className="grid-row grid-header grid-middle">
                            ICD
                        </div>
                        <div className="grid-row grid-header grid-right">
                            No ICD
                        </div>
                        <div className="grid-row grid-row-1 grid-left">
                            Fewer patients who got an ICD died from <span className="strong">all-causes</span>.<sup>9</sup> {this.redStars()}
                        </div>
                        <div className="grid-row grid-row-1 grid-middle">
                            <div className="grid-people">{this.createGrid(20, 100)}</div>
                            <div className="grid-label"><span className="strong">20 out of 100 patients</span> who got an ICD died over 6 years.</div>
                        </div>
                        <div className="grid-row grid-row-1 grid-right">
                            <div className="grid-people">{this.createGrid(24, 100)}</div>
                            <div className="grid-label"><span className="strong">24 out of 100 patients</span> without an ICD died over 6 years.</div>
                        </div>
                        <div className="grid-row grid-row-2 grid-left">
                            Fewer patients who got an ICD died from <span className="strong">sudden cardiac causes i.e. arrhythmias.</span><sup>10</sup> {this.redStars()}
                        </div>
                        <div className="grid-row grid-row-2 grid-middle">
                            <div className="grid-people">{this.createGrid(3, 100)}</div>
                            <div className="grid-label"><span className="strong">3 out of 100 patients</span> who got an ICD died over 6 years.</div>
                        </div>
                        <div className="grid-row grid-row-2 grid-right">
                            <div className="grid-people">{this.createGrid(7, 100)}</div>
                            <div className="grid-label"><span className="strong">7 out of 100 patients</span> without an ICD died over 6 years.</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.redStars()} Represents higher level of evidence
                </div>

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(Benefits);