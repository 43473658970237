import { Component } from 'react';

class StorageComponent extends Component {

    localStorageKey = 'electronic-pda'

    initialState = {
        padit: {},
        age: '',
        reason: '',
        shocks: '',
        conditions: {},
        replace_benefits: {},
        what_matters: '',
        benefits: '',
        clear: '',
        support: '',
        choice: '',
        making_difficult: '',
        questions_concerns: ''
    }

    setLocalStorageKey(key) {
        this.localStorageKey = key
    }

    /**
     * Set the state, persist to local storage
     * @param {*} state
     */
    setState(state) {
        window.localStorage.setItem(this.localStorageKey, JSON.stringify(state));
        super.setState(state);
    }

    /**
     * Get the local storage data for the component
     * @returns
     */
    getLocalStorageData() {
        return JSON.parse(localStorage.getItem(this.localStorageKey));
    }

    /**
     * Set initial state of elements from local storage
     */
    componentDidMount() {
        var replaceData = this.getLocalStorageData()

        if (replaceData) {
            if (replaceData.padit) {
                this.padit = replaceData.padit;
            }
            if (replaceData.conditions) {
                this.conditions = replaceData.conditions;
            }
            if (replaceData.replace_benefits) {
                this.replace_benefits = replaceData.replace_benefits;
            }
            this.setState({
                padit: replaceData.padit,
                age: replaceData.age,
                reason: replaceData.reason,
                shocks: replaceData.shocks,
                conditions: replaceData.conditions,
                replace_benefits: replaceData.replace_benefits,
                what_matters: replaceData.what_matters,
                benefits: replaceData.benefits,
                clear: replaceData.clear,
                support: replaceData.support,
                choice: replaceData.choice,
                making_difficult: replaceData.making_difficult,
                questions_concerns: replaceData.questions_concerns
            })
        } else {
            this.setState(this.initialState)
        }
    }
}

export default StorageComponent;
