import React, { Component } from 'react';

import '../scss/compare.scss';

import withAnIcd from '../images/with-an-icd.png';
import withoutAnIcd from '../images/without-an-icd.png';

class Matlock extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <h5>Consider two possible paths:</h5>
                </div>

                <div className="row grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="compare compare-icd">
                        <h4>Path 1</h4>
                        <div className="detail">
                            You may choose to get an ICD. You may be feeling like you usually do, then a dangerous heart rhythm could happen.
                            The ICD may help you live longer by treating a dangerous heart rhythm. You will continue to live with heart failure
                            that may get worse over time.
                        </div>
                        <div className="image-feature">
                            <div className="image">
                                <img className="image-lg" src={withAnIcd} alt="Graphic - With an ICD" />
                            </div>
                        </div>
                        <div className="detail pt-8">
                            "I'm not ready to die. I have so much I'm trying to stay alive for. Even if it means getting shocked, I'm willing
                            to do anything that can help me live longer."
                        </div>
                    </div>
                    <div className="compare compare-icd">
                        <h4>Path 2</h4>
                        <div className="detail">
                            You may choose to NOT get an ICD. You may be feeling like you usually do, and then a dangerous heart rhythm could
                            happen. You may die quickly from the dangerous heart rhythm. This can happen at any time.
                            </div>
                        <div className="image-feature">
                            <div className="image">
                                <img className="image-lg" src={withoutAnIcd} alt="Graphic - Without an ICD" />
                            </div>
                        </div>
                        <div className="detail pt-8">
                            "I've lived a good life. The idea of dying quickly sounds like a painless way to go. I've always said I hope to die
                            in my sleep. Going through surgery and getting shocked is not the kind of thing I want."
                        </div>
                    </div>
                    <div className="lg:col-start-2 lg:pl-2 source">
                        Source: Matlock, D. University of Colorado, 2017.
                    </div>
                </div>

                {/* <div className="row grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="compare no-border">
                    <div className="image-feature">
                        <div className="label">
                            With an ICD
                        </div>
                        <div className="image">
                            <img className="image-lg" src={withAnIcd} alt="Graphic - With an ICD" />
                        </div>
                        <div className="source">
                            Source: Matlock, D. University of Colorado, 2017.
                        </div>
                    </div>
                </div>
                <div className="compare no-border">
                    <div className="image-feature">
                        <div className="label">
                            Without an ICD
                            </div>
                        <div className="image">
                            <img className="image-lg" src={withoutAnIcd} alt="Graphic - Without an ICD" />
                        </div>
                        <div className="source">
                            Source: Matlock, D. University of Colorado, 2017.
                        </div>
                    </div>
                </div>
                </div> */}
            </div>
        );
    }
}

export default Matlock;
