import { withRouter } from 'react-router-dom';

import Benefits from './Benefits';
import WhatMatters from './WhatMatters';
import AbstractPaditScoreCalculator from '../AbstractPaditScoreCalculator';
import ButtonGroup from '../../partials/ButtonGroup';

class PaditScoreCalculator extends AbstractPaditScoreCalculator {
    static url = '/replace/risks';

    localStorageKey = 'replace';

    figureColour = 'blue';

    handleBack() {
        this.props.history.push(Benefits.url);
    }

    handleNext() {
        this.props.history.push(WhatMatters.url);
    }

    render() {
        return (
            <div>
                {this.getCalculatorHtml()}

                <div className="row print:hidden">
                    <h3>What are other risks of replacing your ICD?</h3>
                    <div>
                        These 100 figures (&nbsp;<i className="fas fa-male"></i>&nbsp;) show a best estimate of what happens to 100 people. The blue figures (&nbsp;<i className="fas fa-male fa-blue"></i>&nbsp;)
                        show the number of people affected. These estimates of risk may increase if you are older, have kidney problems, if you take certain medications, or if you have had your ICD replaced
                        before. Talk to your doctor about your personal risks.
                    </div>
                </div>

                <div className="grid-container print:hidden">
                    <div className="grid-section">
                        <div className="grid-row grid-header grid-left">
                            MAJOR Complications
                        </div>
                        <div className="grid-row grid-header grid-middle">
                            Replacing ICD
                        </div>
                        <div className="grid-row grid-header grid-right">
                            Not Replacing ICD
                        </div>
                        {/* <div className="grid-row grid-row-1 grid-left">
                            Infected ICD site within 12 months after the ICD is replaced. An infected ICD site is serious. It often requires surgery with a laser to
                            remove the ICD unit and the wires, and up to 6 weeks of antibiotics in hospital. 1 out of 100 people with an infection will die.*
                        </div>
                        <div className="grid-row grid-row-1 grid-middle">
                            <div className="grid-label">2 have an infection</div>
                            <div className="grid-label">98 have no infection</div>
                            <div className="grid-people">{this.createGrid(2, 100)}</div>
                        </div>
                        <div className="grid-row grid-row-1 grid-right">
                            No infection
                        </div> */}
                        <div className="grid-row grid-row-2 grid-left">
                            Repeat surgery within 12 months after the ICD is replaced due to a problem such as bleeding, issue with the wires, or pain.*

                        </div>
                        <div className="grid-row grid-row-2 grid-middle">
                            <div className="grid-label">2 need repeat surgery</div>
                            <div className="grid-label">98 do not need repeat surgery</div>
                            <div className="grid-people">{this.createGrid(2, 100)}</div>
                        </div>
                        <div className="grid-row grid-row-2 grid-right">
                            No repeat surgery
                        </div>
                    </div>
                    <div className="grid-section">
                        <div className="grid-row grid-header grid-left">
                            MINOR Complications
                        </div>
                        <div className="grid-row grid-header grid-middle">
                            Replacing ICD
                        </div>
                        <div className="grid-row grid-header grid-right">
                            Not Replacing ICD
                        </div>
                        <div className="grid-row grid-row-1 grid-left">
                            Infection of the incision within 12 months of the ICD being replaced that does not require surgery.*
                        </div>
                        <div className="grid-row grid-row-1 grid-middle">
                            <div className="grid-label">1 has an infection</div>
                            <div className="grid-label">99 have no infection</div>
                            <div className="grid-people">{this.createGrid(1, 100)}</div>
                        </div>
                        <div className="grid-row grid-row-1 grid-right">
                            No incisional infection
                        </div>
                        <div className="grid-row grid-row-2 grid-left">
                            Persistent pain and/or discomfort of the incision within 12 months of the ICD being replaced that does not require surgery.*
                        </div>
                        <div className="grid-row grid-row-2 grid-middle">
                            <div className="grid-label">1 has a pain/discomfort</div>
                            <div className="grid-label">99 have no pain/discomfort</div>
                            <div className="grid-people">{this.createGrid(1, 100)}</div>
                        </div>
                        <div className="grid-row grid-row-2 grid-right">
                            No new pain/discomfort
                        </div>
                        <div className="grid-row grid-row-3 grid-left">
                            Large bruise at the incision which may cause discomfort, pain and/or discoloration within 12 months of the ICD being replaced.*
                        </div>
                        <div className="grid-row grid-row-3 grid-middle">
                            <div className="grid-label">1 has a large bruise</div>
                            <div className="grid-label">99 do not have a large bruise</div>
                            <div className="grid-people">{this.createGrid(1, 100)}</div>
                        </div>
                        <div className="grid-row grid-row-3 grid-right">
                            No bruising
                        </div>
                    </div>
                </div>
                <div className="row print:hidden">
                    * These estimates are based on current research; they could change if a new study is done.
                </div>

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        )
    }
}

export default withRouter(PaditScoreCalculator);