import { withRouter } from 'react-router-dom';

import Benefits from './Benefits';
import WhatMatters from './WhatMatters';
import AbstractPaditScoreCalculator from '../AbstractPaditScoreCalculator';

class PaditScoreCalculator extends AbstractPaditScoreCalculator {
    static url = '/consider/risks';

    localStorageKey = 'consider';

    figureColour = 'red';

    handleBack() {
        this.props.history.push(Benefits.url);
    }

    handleNext() {
        this.props.history.push(WhatMatters.url);
    }
}

export default withRouter(PaditScoreCalculator);