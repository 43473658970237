import React from 'react';
import { withRouter } from 'react-router-dom';

import StorageComponent from '../StorageComponent';
import ButtonGroup from '../../partials/ButtonGroup';
import Choices from './Choices';
import PaditScoreCalculator from './PaditScoreCalculator';

import notImportant from '../../images/replace/not-important.png';
import veryImportant from '../../images/replace/very-important.png';

class WhatMatters extends StorageComponent {
    static url = '/replace/what-matters';

    localStorageKey = 'replace';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);

        this.onChangeWhatMatters = this.onChangeWhatMatters.bind(this);

        this.state = this.initialState;
    }

    handleBack() {
        this.props.history.push(PaditScoreCalculator.url);
    }

    handleNext() {
        this.props.history.push(Choices.url);
    }

    onChangeWhatMatters(e) {
        this.setState({ ...this.state, what_matters: e.target.value });
    }

    createRadios = () => {
        let radios = [];

        for (let index = 1; index <= 10; index++) {
            radios.push(
                <div key={index} className="radio-group">
                    <label>{index}</label>
                    <input type="radio" name="what_matters" value={index} checked={parseInt(this.state.what_matters) === index} onChange={this.onChangeWhatMatters}/>
                </div>
            );
        }

        return radios;
    }

    notImportant = () => {
        return <div>
            <div className="image"><img src={notImportant} alt="Graphic - Not Important" /></div>
            <div className="font-bold">Not Important</div>
            <div>
                Live whatever time I have left without a defibrillator. This means I may die quickly (e.g. dying
                suddenly in my sleep).
            </div>
        </div>
    }

    veryImportant = () => {
        return <div>
            <div className="image"><img src={veryImportant} alt="Graphic - Very Important" /></div>
            <div className="font-bold">Very Important</div>
            <div>
                I wish to live as long as possible, even if that means receiving shocks from a defibrillator,
                facing risks associated with the defibrillator, or living with an illness that might get worse over time.
            </div>
        </div>
    }

    render() {
        return (
            <div className="option-knowledge">
                <h1>Consider What Matters Most To You</h1>

                <div className="row">
                    <h5>On a scale of 1 to 10...</h5>
                </div>

                <div className="row my-4 text-center">
                    How important is it to you to lower your chances of a sudden cardiac death?
                </div>

                <div className="my-12 mx-auto p-4 text-center row grid grid-cols-12 border-4 print:border-0">
                    <div className="col-span-6 mt-4 lg:mt-0 pr-2">
                        {this.notImportant()}
                    </div>
                    <div className="col-span-6 mt-4 lg:mt-0 pl-2">
                        {this.veryImportant()}
                    </div>
                    <div className="col-span-12 mt-4">
                        {this.createRadios()}
                    </div>
                </div>

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(WhatMatters);