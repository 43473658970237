import React, { Component } from 'react';

import ButtonGroup from '../../partials/ButtonGroup';
import Additional from './Additional';
import Choices from './Choices';

class Faq extends Component {
    static url = '/replace/faq';

    questions = [
        {
            text: 'How long would I have to wait for replacement surgery?',
            answer: 'The wait time to have an ICD replaced is about 3 to 6 weeks.'
        },
        {
            text: 'How long is the recovery after surgery?',
            answer: 'For 7 days after surgery, we ask you to leave the incision dry (do not get the incision wet), and avoid lifting any object greater than 10 pounds.'
        },
        {
            text: 'Can I drive after the surgery?',
            answer: 'On the day of surgery, you will need a ride home. If you were able to legally drive before having your ICD replaced, you may drive after.'
        },
        {
            text: 'If I choose not to replace my ICD battery, is the ICD taken out?',
            answer: 'Usually, the ICD is left in place. Removing the ICD requires surgery. Any surgery places you at increased risk of problems such as infection or bruising. We strongly suggest that you leave it in place.'
        },
        {
            text: 'If I choose not to replace my ICD battery, do I still need to come in for regular visits to the device clinic?',
            answer: 'No, there is no need to come to the device clinic for regular visits. But, if you wish to have the ICD therapies (e.g. anti-tachycardic pacing and shocks) or alerts turned off before the battery is fully worn out, you will need to come to the device clinic. At any time, if you have any questions or concerns, the staff in the device clinic can answer them by phone during regular clinic hours.'
        },
        {
            text: 'Is it possible to reverse my decision regarding replacement or turning off/deactivating my ICD?',
            answer: 'Yes, you may change your mind at any time. If you change your mind, please contact either the device clinic nurse or your doctor. If the battery has depleted and you want to have it replaced, you will need surgery to replace the entire unit.'
        }
    ]

    constructor(props) {
        super(props);
        this.state = {
            questions: this.questions
        }
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleBack() {
        this.props.history.push(Choices.url);
    }

    handleNext() {
        this.props.history.push(Additional.url);
    }

    render() {
        return (
            <div className="faq">
                <h1>Frequently Asked Questions</h1>

                {this.state.questions.map((question) =>
                    <div className="question">
                        <div className="strong pb-2">
                            {question.text}
                        </div>
                        <div>
                            {question.answer}
                        </div>
                    </div>
                )}

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default Faq;