import React, { Component } from 'react';

class ButtonGroup extends Component {
    static defaultProps = {
        hideReset: true
    }

    handleReset() {
        console.log('default click reset handler');
    }

    getBack = () => {
        if (!this.props.hideBack) {
            return <button className="btn btn--primary" onClick={this.props.handleBack}>&lt;&lt; Back</button>;
        }
    }

    getReset = () => {
        if (!this.props.hideReset) {
            return <button className="btn btn--primary ml-2 md:ml-8" onClick={this.handleReset}>Reset</button>;
        }
    }

    getNext = () => {
        if (!this.props.hideNext) {
            return <button className="btn btn--primary ml-2 md:ml-8" onClick={this.props.handleNext}>Next &gt;&gt;</button>;
        }
    }

    render() {
        return (
            <div className="btn-group print:hidden">
                {this.getBack()}
                {this.getReset()}
                {this.getNext()}
            </div>
        );
    }
}

export default ButtonGroup;