import React, { Component } from 'react';

class Button extends Component {
    static defaultProps = {
        text: "Continue",
        handleClick: this.handleClick
    }

    handleClick() {
        console.log('default click handler');
    }

    render() {
        return (
            <div className="btn-group print:hidden">
                <button className="btn btn--primary" onClick={this.props.handleClick}>{this.props.text}</button>
            </div>
        );
    }
}

export default Button;