import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../scss/compare.scss';

import ButtonGroup from '../../partials/ButtonGroup';
import IntroductionContinued from './IntroductionContinued';
import Benefits from './Benefits';
import Matlock from '../../partials/Matlock';

import implantIcd from '../../images/implant-icd.png';
import continueWithMedicalManagement from '../../images/continue-with-medical-management.png';

class KnowYourOptions extends Component {
    static url = '/consider/know-your-options';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleBack() {
        this.props.history.push(IntroductionContinued.url);
    }

    handleNext() {
        this.props.history.push(Benefits.url);
    }

    render() {
        return (
            <div className="know-your-options">
                <h1>Know Your Options</h1>

                <div className="row">
                    The decision to have an ICD implanted is not a simple one. The answer will depend on what feels right for you and what you think will fit best in your life. Your clinicians want to help you make the best decision for you.
                </div>

                <div className="row compare no-border">
                    <h3>Understand the Benefits of the Options</h3>
                    <ul>
                        <li>The ICD may lower your risk of a sudden cardiac death from a fast dangerous heart rhythm.</li>
                        <li>The ICD may give you peace of mind that if you have a fast dangerous heart rhythm it could be corrected.</li>
                        <li>The ICD is proven to successfully treat fast dangerous heart rhythms.</li>
                        <li>Over 4 years, 21 out of 100 people with ICM will receive appropriate shocks/ICD therapy.<sup>1</sup> Over 6 years, 16 out
                            of 100 people with NICM will receive appropriate shock/ICD.<sup>2</sup></li>
                        <li>In Canada, there is no cost for patients to receive an ICD.</li>
                    </ul>
                    {/* <div className="image-feature">
                        <div className="label">
                            With an ICD
                        </div>
                        <div className="image">
                            <img className="image-lg" src={withAnIcd} alt="Graphic - With an ICD" />
                        </div>
                        <div className="source">
                            Source: Matlock, D. University of Colorado, 2017.
                        </div>
                    </div> */}
                </div>
                <div className="row compare no-border">
                    <h3>Understand the Risks of the Options</h3>
                    <p className="strong">
                        Risks of ICD surgery:
                    </p>
                    <ul>
                        <li>There is a chance of infection from the ICD surgery: 5% of those with ICM,<sup>3</sup> and 4% of those with NICM<sup>4</sup>.</li>
                        <li>
                            There could be bleeding at the time of the ICD surgery.
                        </li>
                        <li>
                            A lung could collapse at the time of the ICD surgery.
                        </li>
                    </ul>
                    <p className="strong">
                        Risks over the long term:
                    </p>
                    <ul>
                        <li>
                            Over 4 years, 10 out of 100 people with ICM will receive inappropriate shocks/ICD therapy.<sup>5</sup> Over 6
                            years, 8 out of 100 people with NICM will receive inappropriate shocks/ICD therapy.<sup>6</sup>
                        </li>
                        <li>There may be problems with the wires (leads) over the short or long term.</li>
                        <li>
                            You may face short term driving restrictions if the ICD delivers <span className="italic">appropriate</span> shocks/therapy
                            to treat a fast dangerous heart rhythm.
                        </li>
                        <li>If you receive shocks from the ICD, you may worry about future shocks.</li>
                    </ul>
                    {/* <div className="image-feature">
                        <div className="label">
                            Without an ICD
                            </div>
                        <div className="image">
                            <img className="image-lg" src={withoutAnIcd} alt="Graphic - Without an ICD" />
                        </div>
                        <div className="source">
                            Source: Matlock, D. University of Colorado, 2017.
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <h3>You have two options:</h3>
                </div>

                <div className="row grid grid-cols-11 gap-4">
                    <div className="text-center col-span-5">
                        <div className="image"><img src={implantIcd} alt="Graphic - Implant ICD" /></div>
                        <h2>Implant ICD<br/> and continue with medical management</h2>
                    </div>
                    <div className="text-center">
                        OR
                    </div>
                    <div className="text-center col-span-5">
                        <div className="image"><img src={continueWithMedicalManagement} alt="Graphic - Continue with Medical Management" /></div>
                        <h2>Continue with medical management</h2>
                    </div>
                </div>

                <div className="row grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="compare compare-columns">
                        <h4>Get an ICD</h4>

                        <div className="row row-what">
                            <div className="title"><span>What?</span></div>
                            <div className="detail">
                                Have an ICD implanted.
                            </div>
                        </div>
                        <div className="row row-how">
                            <div className="title"><span>How?</span></div>
                            <div className="detail">
                                An incision is made to open the skin to place the ICD. You are awake during the surgery. The doctor will inject
                                freezing into the area and will give you medicine to relax you. The surgery lasts about 45 minutes.
                            </div>
                        </div>
                        <div className="row row-who">
                            <div className="title"><span>Who?</span></div>
                            <div className="detail">
                                People who have either ischemic (narrowing of the arteries) or non-ischemic heart disease (heart disease
                                from other factors).
                            </div>
                        </div>
                        <div className="row row-length">
                            <div className="title"><span>Length of Hospital Stay?</span></div>
                            <div className="detail">
                                On average, 2 to 4 hours. You will return home the same day.
                            </div>
                        </div>
                        <div className="row row-recover">
                            <div className="title"><span>Recovery Time?</span></div>
                            <div className="detail">
                                On average, 5 to 7 days.
                            </div>
                        </div>
                        <div className="row row-next">
                            <div className="title"><span>Follow Up?</span></div>
                            <div className="detail">
                                Every 3 to 6 months. After 5 to 10 years, the ICD will need to be replaced.
                            </div>
                        </div>
                    </div>
                    <div className="compare compare-columns">
                        <h4>Do not get an ICD and continue with medications</h4>

                        <div className="row row-what">
                            <div className="title"><span>What?</span></div>
                            <div className="detail">
                                Continue with your usual medications.
                            </div>
                        </div>
                        <div className="row row-how">
                            <div className="title"><span>How?</span></div>
                            <div className="detail">
                                You will meet regularly with your cardiologist and other health care providers to monitor medications.
                            </div>
                        </div>
                        <div className="row row-who">
                            <div className="title"><span>Who?</span></div>
                            <div className="detail">
                                People who work as welders and need to be near magnetic fields would not be able to have an ICD. You would be managed with medications.
                            </div>
                        </div>
                        <div className="row row-length">
                            <div className="title"><span>Length of Hospital Stay?</span></div>
                            <div className="detail">
                                None.
                            </div>
                        </div>
                        <div className="row row-recover">
                            <div className="title"><span>Recovery Time?</span></div>
                            <div className="detail">
                                None.
                            </div>
                        </div>
                        <div className="row row-next">
                            <div className="title"><span>Follow Up?</span></div>
                            <div className="detail">
                                Regularly, as suggested by your cardiologist/ health care team.
                            </div>
                        </div>
                    </div>
                </div>

                <Matlock />

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(KnowYourOptions);