import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import IntroductionContinued from './IntroductionContinued';
import Button from '../../partials/Button';

class Introduction extends Component {
    static url = '/consider';

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.history.push(IntroductionContinued.url);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <h3>This Decision Aid is for You if:</h3>
                    <ul>
                        <li>You are thinking about an ICD because your doctor believes you could be at risk for a fast dangerous heart rhythm - but you have never had one.</li>
                        <li>ICDs are for people who have either <span className="strong">ischemic cardiomyopathy (ICM)</span> or <span className="strong">non-ischemic cardiomyopathy (NICM)</span>.</li>
                        <li>You want to prepare for a visit with a health professional about an ICD.</li>
                        <li>You want to think about the benefits and risks of your treatment options and what matters most to you and your family.</li>
                        <li>You currently do not have an ICD.</li>
                    </ul>
                </div>

                <div className="row">
                    <h3>This Decision Aid is Not for You if:</h3>
                    <ul>
                        <li>
                            You are thinking about a subcutaneous ICD (S-ICD). This is different than a traditional ICD because the wires (leads) that connect the device to your heart
                            are implanted under the skin rather than through the veins.
                        </li>
                        <li>You are thinking about an ICD with an "extra" wire that can improve the way your heart beats (called a cardiac re-synchronization therapy (CRT-ICD) device).</li>
                        <li>You are thinking about a pacemaker.</li>
                    </ul>
                </div>

                <Button text="This decision aid applies to me. Begin." handleClick={this.handleClick} />
            </div>

        );
    }
}

export default withRouter(Introduction);