import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../partials/Button';
import KnowYourOptions from './KnowYourOptions';

class IntroductionContinued extends Component {
    static url = '/replace/introduction';

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.history.push(KnowYourOptions.url);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <h3>Refresher: How does your ICD work?</h3>
                </div>
                <div className="row">
                    The ICD keeps track of your heart rate and rhythm. It is meant to treat sudden cardiac arrest in people who are at
                    risk of fast dangerous heart rhythms (e.g. ventricular tachycardia, ventricular fibrillation). Your ICD monitors your
                    heart rhythm, detects a dangerous fast rhythm, and delivers an electrical shock to restore a normal heart rhythm. Those
                    who have had a shock describe it as receiving a "kick in the chest." An ICD is not a pacemaker. A pacemaker helps the heart beat,
                    but does not give shocks like an ICD.
                </div>

                <div className="row grid grid-cols-1 2xl:grid-cols-2 gap-4">
                    <div>
                        <div className="mb-2">
                            <h3>Your ICD does not:</h3>
                            <ul>
                                <li>Relieve symptoms like trouble breathing, feeling tired, or chest pain.</li>
                                <li>Prevent dangerous heart rhythms from starting.</li>
                                <li>Treat irregular heart rhythms such as atrial fibrillation.</li>
                                <li>Stop the progression of your heart disease.</li>
                                <li>Prevent all causes of death. It can only save your life from a fast dangerous heart rhythm. Even with a defibrillator, you may die from another heart related or non-heart related cause.</li>
                            </ul>
                        </div>

                        <div className="my-2">
                            <h3>As You Go Through This Information, Think About:</h3>
                            <ul>
                                <li>How you want to live the rest of your life?</li>
                                <li>What you hope for?</li>
                                <li>What you fear?</li>
                                <li>What questions may you have for your nurse or doctor?</li>
                            </ul>
                        </div>
                    </div>
                    <div className="question-box">
                        <hr />
                        <div className="heading">
                            Did you know?
                        </div>
                        <div className="content">
                            About 3 months before the battery is fully worn out, you may hear an alarm or feel a vibration from your ICD.  If this occurs, the
                            ICD will still function if it detects a fast dangerous heart rhythm. If you hear or feel the alert, call the device clinic.
                        </div>
                        <hr />
                    </div>
                </div>


                <Button text="Next &gt;&gt;" handleClick={this.handleClick} />
            </div>

        );
    }
}

export default withRouter(IntroductionContinued);