import React, { Component } from 'react';

import PaditScoreCalculator from './PaditScoreCalculator';
import WhatMatters from './WhatMatters';

class Print extends Component {
    render() {
        return (
            <div className="consider print">
                <div className="mx-4">
                    <PaditScoreCalculator/>
                </div>

                <div className="mx-4 print-page-break">
                    <WhatMatters/>
                </div>
            </div>
        )
    }
}

export default Print;