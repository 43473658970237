import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../scss/additional.scss';
// import ButtonGroup from '../../partials/ButtonGroup';
import Choices from './Choices';

import logoMcMasterImg from '../../images/logos/mcmaster.png';

class Additional extends Component {
    static url = '/consider/info';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
    }

    handleBack() {
        this.props.history.push(Choices.url);
    }

    render() {
        return (
            <div className="info">
                <h1>Additional Information</h1>

                <div className="row">
                    Canadian website with patient/family information: <a href="https://www.ottawaheart.ca/document/icds-implantable-cardioverter-defibrillators" target="_blank" rel="noreferrer">https://www.ottawaheart.ca/document/icds-implantable-cardioverter-defibrillators</a>
                </div>
                <div className="row my-4">
                    Copyright &copy; 2022.
                </div>
                <div className="row">
                    Primary content editor: Sandra L. Carroll RN PhD, McMaster University.
                </div>
                <div className="row">
                    This patient decision aid was developed by a team of researchers, including patients, family members, and clinicians
                    with expertise in shared decision-making and ICDs. For more information, please contact S. Carroll directly
                    at <a href="mailto:carroll@mcmaster.ca">carroll@mcmaster.ca</a>.
                </div>
                <div className="mt-8">
                    Original version funded by Canadian Institutes of Health Research.
                </div>
                <div className="row">
                    Current version funded in part by Canadian Cardiovascular Society and CANet.
                </div>
                <div className="row">
                    None of the authors stand to gain or lose from the choices patients make after using this decision aid.
                </div>

                <h1 className="mt-12">Sources</h1>

                <div className="row">
                    <sup>1</sup> Based on data from 1 RCT (SCD-HeFT, 2005) with a total sample size of 431 for ICD group.
                </div>
                <div className="row">
                    <sup>2</sup> Based on data from 3 RCT's (CAT, 2002; DANISH, 2016; SCD-HeFT, 2005) with a total sample size of 1,004 for ICD group.
                </div>
                <div className="row">
                    <sup>3</sup> Based on data from 3 RCT's (CABG-PATCH, 1996; MADIT I, 1996; MADIT II, 2002) with a total sample size of 1,283 for ICD group.
                </div>
                <div className="row">
                    <sup>4</sup> Based on data from 3 RCT's (CAT, 2002; DANISH, 2016; DEFINITE, 2004) with a total sample size of 835 for ICD group.
                </div>
                <div className="row">
                    <sup>5</sup> Based on data from 1 RCT (SCD-HeFT, 2005) with a total sample size of 431 for ICD group.
                </div>
                <div className="row">
                    <sup>6</sup> Based on data from 2 RCT's (DANISH, 2016; SCD-HeFT, 2005) with a total sample size of 954 for ICD group.
                </div>
                <div className="row">
                    <sup>7</sup> Based on data from 6 RCT's (CABG-PATCH, 1996; DINAMIT, 2004; IRIS, 2009; MADIT I, 1996; MADIT II, 2002, SCD-HeFT, 2005) with
                    a total sample size of 2,491 for ICD group and 2,719 for non-ICD standard medical therapy group.
                </div>
                <div className="row">
                    <sup>8</sup> Based on data from 5 RCT's (CABG-PATCH, 1996; DINAMIT, 2004; IRIS, 2009; MADIT I, 1996; MADIT II, 2002) with a total sample
                    size of 2,060 for ICD group and 1,840 for non-ICD standard medical therapy group.
                </div>
                <div className="row">
                    <sup>9</sup> Based on data from 5 RCT's (AMIOVIRT, 2003; CAT, 2002; DANISH, 2016; DEFINITE, 2004; SCD-HeFT, 2005) with a total sample
                    size of 1,284 for ICD group and 1,708 for non-ICD standard medical therapy group.
                </div>
                <div className="row">
                    <sup>10</sup> Based on data from 3 RCT's (AMIOVIRT, 2003; DANISH, 2016; DEFINITE, 2004) with a total sample size of 836 for ICD group
                    and 841 for non-ICD standard medical therapy group.
                </div>

                {/* <ButtonGroup handleBack={this.handleBack} hideNext={true} /> */}

                <div className="row flex items-center justify-center grid grid-cols-1 lg:pt-12">
                    <div className="image logo-image">
                        <img src={logoMcMasterImg} className="image-sm" alt="McMaster University" />
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(Additional);