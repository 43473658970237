import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

// import logo from '../images/uohi-logo.png';

import Index from '../pages/Index';

class Header extends Component {
    render() {
        return (
            <header className="hidden">
                <nav id="header">
                    <div className="mx-auto w-full flex flex-1 pl-4 py-4">
                        <div className="w-1/4 mr-4">
                            <NavLink to={Index.url}>
                                {/* &lt; &lt; Back to Landing Page */}
                                {/* <img src={logo} className="h-12" alt="University of Ottawa Heart Institute" /> */}
                            </NavLink>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;