import React from 'react';
import { withRouter } from 'react-router-dom';

import '../../scss/choices.scss';
import StorageComponent from '../StorageComponent';
import Button from '../../partials/Button';
import ButtonGroup from '../../partials/ButtonGroup';
import Faq from './Faq';
import WhatMatters from './WhatMatters';
import Print from './Print';

class Choices extends StorageComponent {
    static url = '/replace/choices';

    localStorageKey = 'replace';

    changeFunctions;

    componentRef = null;

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);

        this.changeFunctions = {
            'benefits': this.onChangeBenefits.bind(this),
            'clear': this.onChangeClear.bind(this),
            'support': this.onChangeSupport.bind(this),
            'choice': this.onChangeChoice.bind(this),
            'making_difficult': this.onChangeMakingDifficult.bind(this),
            'questions_concerns': this.onChangeQuestionsConcerns.bind(this)
        }

        this.state = this.initialState;
    }

    handleBack() {
        this.props.history.push(WhatMatters.url);
    }

    handleNext() {
        this.props.history.push(Faq.url);
    }

    onChangeBenefits(e) {
        this.setState({ ...this.state, benefits: e.target.value });
    }

    onChangeClear(e) {
        this.setState({ ...this.state, clear: e.target.value });
    }

    onChangeSupport(e) {
        this.setState({ ...this.state, support: e.target.value });
    }

    onChangeChoice(e) {
        this.setState({ ...this.state, choice: e.target.value });
    }

    onChangeMakingDifficult(e) {
        this.setState({ ...this.state, making_difficult: e.target.value });
    }

    onChangeQuestionsConcerns(e) {
        this.setState({ ...this.state, questions_concerns: e.target.value });
    }

    createYesNo = (name) => {
        return <div className="checkbox-group">
            <label>
                <input type="checkbox" name={name} value="yes" checked={this.state[name] === "yes"} onChange={this.changeFunctions[name]} />
                <span className="mx-2">Yes</span>
            </label>
            <label>
                <input type="checkbox" name={name} value="no" checked={this.state[name] === "no"} onChange={this.changeFunctions[name]} />
                <span className="mx-2">No</span>
            </label>
        </div>

    }

    handlePrintClick() {
        window.print();
    }

    getPrintButton() {
        if ( !this.props.hidePrintButton ) {
            return (
                <Button text="Print Summary" handleClick={this.handlePrintClick} />
            );
        }
    }

    render() {
        return (
            <div className="choices">
                <Print/>

                <h1 className="mt-4 print-page-break">What Else Do You Need to Make a Choice?</h1>

                <div className="question-form question-form-3">
                    <div className="text">
                        Do you know the benefits and risks of each option?
                    </div>
                    <div className="radio short">
                        {this.createYesNo('benefits')}
                    </div>
                </div>
                <div className="question-form question-form-3">
                    <div className="text">
                        Are you clear about which benefits and risks matter most to you?
                    </div>
                    <div className="radio short">
                        {this.createYesNo('clear')}
                    </div>
                </div>
                <div className="question-form question-form-3">
                    <div className="text">
                        Do you have enough support from others to make a choice?
                    </div>
                    <div className="radio short">
                        {this.createYesNo('support')}
                    </div>
                </div>
                <div className="question-form question-form-3">
                    <div className="text">
                        Do you feel sure about the best choice for you?
                    </div>
                    <div className="radio short">
                        {this.createYesNo('choice')}
                    </div>
                </div>
                <div className="row row-source">
                    <span className="source">The SURE Test &copy; 2008 O'Connor &amp; Légaré</span>
                </div>
                <div className="question-form mt-2">
                    <div className="text textbox">
                        Is there anything else making your decision difficult?
                    </div>
                    <div className="textbox">
                        <textarea className="mt-2 block w-full" name="making_difficult" value={this.state.making_difficult} rows="6" onChange={this.changeFunctions['making_difficult']}></textarea>
                    </div>
                </div>
                <div className="question-form mt-2">
                    <div className="text textbox">
                        List questions or concerns you want to talk about with your health care provider(s).
                    </div>
                    <div className="textbox">
                        <label className="block">
                            <textarea className="mt-2 block w-full" name="questions_concerns" value={this.state.questions_concerns} rows="6" onChange={this.changeFunctions['questions_concerns']}></textarea>
                        </label>
                    </div>
                </div>

                {this.getPrintButton()}

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(Choices);