import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../scss/compare.scss';

import ButtonGroup from '../../partials/ButtonGroup';
import IntroductionContinued from './IntroductionContinued';
import Benefits from './Benefits';
import Matlock from '../../partials/Matlock';

import replaceDefibrillator from '../../images/replace/replace-defibrillator.png';
import notReplaceDefibrillator from '../../images/replace/not-replace-defibrillator.png';

class KnowYourOptions extends Component {
    static url = '/replace/know-your-options';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleBack() {
        this.props.history.push(IntroductionContinued.url);
    }

    handleNext() {
        this.props.history.push(Benefits.url);
    }

    render() {
        return (
            <div className="know-your-options">
                <h1>Know Your Options</h1>

                <div className="row">
                    For some people, the decision to replace the ICD is not a simple one. The answer depends on what is right
                    for you, and if it still meets your needs. Your clinicians want to help you make the best decision for you.
                </div>

                <div className="row">
                    <h5>You have two options:</h5>
                </div>

                <div className="row grid grid-cols-11 gap-4">
                    <div className="text-center col-span-5">
                        <div className="image"><img src={replaceDefibrillator} alt="Graphic - Replace Defibrillator" /></div>
                        <h2>Replace the ICD (the entire unit)</h2>
                    </div>
                    <div className="text-center">
                        OR
                    </div>
                    <div className="text-center col-span-5">
                        <div className="image"><img src={notReplaceDefibrillator} alt="Graphic - Not Replace Defibrillator" /></div>
                        <h2>Not Replace the ICD</h2>
                    </div>
                </div>
                <div className="row">
                    Even if you choose to replace your ICD now, it is possible that there may come a time when you wish to have the
                    shock function turned off.  This can be done in the device clinic and does not require surgery.  Letting your
                    family know your preferences is important.
                </div>
                <div className="row grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="compare compare-columns">
                        <h4>Replace the ICD</h4>

                        <div className="row row-what">
                            <div className="title"><span>What?</span></div>
                            <div className="detail">
                                Replacing the entire ICD unit using minor surgery.
                            </div>
                        </div>
                        <div className="row row-how">
                            <div className="title"><span>How?</span></div>
                            <div className="detail">
                                An incision is made to open the skin where your ICD is located.  The entire unit is removed and a new
                                unit is inserted in the same place. You are awake during the surgery. The doctor will inject freezing into
                                the area and will give you medicine to relax you. The surgery lasts about 45 minutes.
                            </div>
                        </div>
                        <div className="row row-who">
                            <div className="title"><span>Who?</span></div>
                            <div className="detail">
                                People who continue to be at risk for fast dangerous heart rhythms and want to be treated for them.
                            </div>
                        </div>
                        <div className="row row-length">
                            <div className="title"><span>Length of Hospital Stay?</span></div>
                            <div className="detail">
                                On average, 2 to 4 hours. You will return home the same day.
                            </div>
                        </div>
                        <div className="row row-recover">
                            <div className="title"><span>Recovery Time?</span></div>
                            <div className="detail">
                                On average, 5 to 7 days.
                            </div>
                        </div>
                        <div className="row row-next">
                            <div className="title"><span>Next Follow Up?</span></div>
                            <div className="detail">
                                You are seen in the device clinic about 4 weeks later.
                            </div>
                        </div>
                    </div>
                    <div className="compare compare-columns">
                        <h4>Not Replace the ICD</h4>

                        <div className="row row-what">
                            <div className="title"><span>What?</span></div>
                            <div className="detail">
                                When a person chooses not to replace the ICD battery, it is usually best to leave it in place.
                            </div>
                        </div>
                        <div className="row row-how">
                            <div className="title"><span>How?</span></div>
                            <div className="detail">
                                When the battery is <span className="strong">fully</span> worn out, there is no alert. This does NOT cause death; but it means
                                the ICD would no longer respond to fast dangerous heart rhythms. The device can function until the battery
                                is fully worn out or you may request to have the defibrillator function turned off. This can be done
                                in the device clinic.
                            </div>
                        </div>
                        <div className="row row-who">
                            <div className="title"><span>Who?</span></div>
                            <div className="detail">
                                People who no longer want to have ICD shocks; who worry too much about the ICD; whose health has changed,
                                who are close to death, or other personal reasons.
                            </div>
                        </div>
                        <div className="row row-length">
                            <div className="title"><span>Length of Hospital Stay?</span></div>
                            <div className="detail">
                                None.
                            </div>
                        </div>
                        <div className="row row-recover">
                            <div className="title"><span>Recovery Time?</span></div>
                            <div className="detail">
                                None.
                            </div>
                        </div>
                        <div className="row row-next">
                            <div className="title"><span>Next Follow Up?</span></div>
                            <div className="detail">
                                There is no need to come to the device clinic for regular visits if your ICD is fully worn out or turned off.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-center strong text-base pt-2">
                    It is not wrong to stop any medical treatment if it no longer serves your health care goals.
                </div>

                <div className="row">
                    <h3>To understand your options, it is important to discuss how the defibrillator can affect the way you pass away.</h3>
                </div>

                <Matlock />

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(KnowYourOptions);