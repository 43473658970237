import React from 'react';
import { withRouter } from 'react-router-dom';

import StorageComponent from '../StorageComponent';
import ButtonGroup from '../../partials/ButtonGroup';
import PaditScoreCalculator from './PaditScoreCalculator';
import Choices from './Choices';

import notImportant from '../../images/consider/not-important.png';
import veryImportant from '../../images/consider/very-important.png';

class WhatMatters extends StorageComponent {
    static url = '/consider/what-matters';

    localStorageKey = 'consider';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);

        this.onChangeWhatMatters = this.onChangeWhatMatters.bind(this);

        this.state = this.initialState;
    }

    handleBack() {
        this.props.history.push(PaditScoreCalculator.url);
    }

    handleNext() {
        this.props.history.push(Choices.url);
    }

    onChangeWhatMatters(e) {
        this.setState({ ...this.state, what_matters: e.target.value });
    }

    createRadios = () => {
        let radios = [];

        for (let index = 1; index <= 10; index++) {
            radios.push(
                <div className="radio-group">
                    <label>{index}</label>
                    <input type="radio" name="what_matters" value={index} checked={parseInt(this.state.what_matters) === index} onChange={this.onChangeWhatMatters}/>
                </div>
            );
        }

        return radios;
    }

    notImportant = () => {
        return <div>
            <div className="image"><img src={notImportant} alt="Graphic - Not Important" /></div>
            <div className="font-bold">Not Important</div>
            <div>
                Live whatever time I have left without a defibrillator. This means I may die quickly (e.g. dying
                suddenly in my sleep).
            </div>
        </div>
    }

    veryImportant = () => {
        return <div>
            <div className="image"><img src={veryImportant} alt="Graphic - Very Important" /></div>
            <div className="font-bold">Very Important</div>
            <div>
                I wish to live as long as possible, even if that means receiving shocks from a defibrillator,
                facing risks associated with the defibrillator, or living with an illness that might get worse over time.
            </div>
        </div>
    }

    render() {
        return (
            <div className="option-knowledge">
                <h1>Consider What Matters Most To You</h1>

                <div className="row print:hidden">
                    <h5>To understand your options, it is important to discuss how an ICD can impact your life.</h5>
                </div>

                <div className="grid-container print:hidden">
                    <div className="grid-section grid-narrow">
                        <div className="grid-row grid-left">
                            Daily life
                        </div>
                        <div className="grid-row grid-right">
                            You can exercise, travel, have sexual relations and use your cell phone.
                        </div>
                        <div className="grid-row grid-left">
                            Driving
                        </div>
                        <div className="grid-row grid-right">
                            You can still drive, but if you have a shock you need to stop for 6 months.
                        </div>
                        <div className="grid-row grid-left">
                            Medical procedures
                        </div>
                        <div className="grid-row grid-right">
                            Special care and management may be required before some medical procedures. For example, in the case of magnetic resonance imaging (MRI). Before
                            any medical procedure, it is advised to talk to your health care team about your ICD.
                        </div>
                        <div className="grid-row grid-left">
                            Non Replacement
                        </div>
                        <div className="grid-row grid-right">
                            At some point, you and your doctor may decide that you no longer want therapy from an ICD. You
                            can choose to not replace the battery.
                        </div>
                        <div className="grid-row grid-left">
                            Quality of life
                        </div>
                        <div className="grid-row grid-right">
                            Some people with an ICD worry about when, where, and during what activity they could receive a shock.
                        </div>
                        <div className="grid-row grid-left">
                            Deactivation
                        </div>
                        <div className="grid-row grid-right">
                            You can choose to have the ICD therapies turned off. This can be done at the device clinic. Letting your
                            family know your preferences through advanced healthcare directives is important.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <h5>On a scale of 1 to 10...</h5>
                </div>

                <div className="row my-4 text-center">
                    How important is it to you to lower your chances of a sudden cardiac death?
                </div>

                <div className="my-12 mx-auto p-4 text-center row grid grid-cols-12 border-4 print:border-0">
                    <div className="col-span-6 mt-4 lg:mt-0 pr-2">
                        {this.notImportant()}
                    </div>
                    <div className="col-span-6 mt-4 lg:mt-0 pl-2">
                        {this.veryImportant()}
                    </div>
                    <div className="col-span-12 mt-4">
                        {this.createRadios()}
                    </div>
                </div>

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(WhatMatters);