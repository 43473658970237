import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../partials/Button';
import IntroductionContinued from './IntroductionContinued';

class Introduction extends Component {
    static url = '/replace';

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.history.push(IntroductionContinued.url);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <h3>This Decision Aid is for You if:</h3>
                    <ul>
                        <li>You have an Implantable Cardioverter-Defibrillator (ICD).</li>
                        <li>Your ICD battery is nearing the time for replacement, most likely because the battery is running out.</li>
                        <li>You want to prepare for a visit with a health professional about your defibrillator.</li>
                        <li>You want to think about the benefits and risks of your treatment options and what matters most to you and your family.</li>
                    </ul>
                </div>

                <Button text="This decision aid is for me.  Start decision aid." handleClick={this.handleClick} />
            </div>

        );
    }
}

export default withRouter(Introduction);