import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import Index from '../Index';

class Header extends Component {
    render() {
        return (
            <div className="bg-gray-800 text-gray-100 flex justify-between">
                {/* logo */}
                <h2 className="block p-4 text-white font-bold">
                    <NavLink to={Index.url}>
                        A Decision Aid For Implantable Cardioverter Defibrillator Replacement
                    </NavLink>
                </h2>

                {/* mobile menu button */}
                <button className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700 xl:hidden print:hidden" onClick={this.props.mobileMenuClick}>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
        );
    }
}

export default Header;