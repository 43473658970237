import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import icd from '../../images/icd.png';
import KnowYourOptions from './KnowYourOptions';
import Button from '../../partials/Button';

class IntroductionContinued extends Component {
    static url = '/consider/introduction';

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.history.push(KnowYourOptions.url);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <h3>What is an ICD?</h3>
                    <ul>
                        <li>An ICD is a small battery-operated device that can detect and deliver therapy to correct dangerous fast heart rhythms. The ICD keeps track of your heart rate and rhythm.</li>
                        <li>An ICD monitors heart rhythms, detects dangerous fast rhythms, and delivers an electric shock to restore a normal heart rhythm.</li>
                        <li>Treating fast dangerous heart rhythms in persons who are known to be <span className="italic">at-risk</span> can prevent a sudden cardiac arrest. This
                        type of ICD therapy is called an <span className="italic">appropriate</span> shock (or therapy).</li>
                    </ul>
                </div>
                <div className="row grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                        <div className="image"><img className="image-lg" src={icd} alt="Graphic - ICD Location" /></div>
                    </div>
                    <div className="lg:mt-10 xl:mt-20">
                        The ICD sits below the skin in your chest so you cannot see it - but you can see the scar and feel a small bump.
                    </div>
                </div>

                <div className="row">
                    <h3>What does ICD therapy feel like?</h3>
                    <ul>
                        <li>
                            If the ICD senses a fast dangerous heart rhythm, it can deliver ICD therapy two ways: 1) a shock, or 2) lower energy therapy. ICD therapy may occur
                            with or without signs or symptoms. Some people feel well. Other people feel light-headed and dizzy. Others may lose consciousness.
                        </li>
                        <li>Shocks have been described as feeling like a strong punch to the chest (it hurts). You may not feel anything if the ICD delivers the lower energy therapy.</li>
                        <li>You could receive ICD therapy when you should not (called an <span className="italic">inappropriate</span> shock).</li>
                    </ul>
                </div>

                <div className="row">
                    <h3>What can't an ICD do?</h3>
                    <ul>
                        <li>An ICD <span className="underline">will not</span> prevent fast dangerous heart rhythms from starting.</li>
                        <li>A standard ICD cannot correct <span className="underline">other</span> heart related symptoms like trouble breathing, feeling tired, or chest pain.</li>
                        <li>A standard ICD does not change your underlying heart disease or heart condition causing the dangerous heart rhythm.</li>
                        <li>The ICD does not prevent you from all causes of death.</li>
                        <li>The ICD does not correct irregular heart rhythms like atrial fibrillation.</li>
                    </ul>
                </div>

                <div className="row">
                    <h3>As You Go Through This Information, Think About:</h3>
                    <ul>
                        <li>How you want to live the rest of your life?</li>
                        <li>What you hope for?</li>
                        <li>What you fear?</li>
                        <li>What questions may you have for your nurse or doctor?</li>
                    </ul>
                </div>

                <Button text="Next &gt;&gt;" handleClick={this.handleClick} />
            </div>

        );
    }
}

export default withRouter(IntroductionContinued);