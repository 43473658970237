import React from 'react';

import '../scss/padit-score-calculator.scss';

import ButtonGroup from '../partials/ButtonGroup';
import StorageComponent from './StorageComponent';

class AbstractPaditScoreCalculator extends StorageComponent {

    score = 0;
    risk = 0;
    riskLabel = 'low';
    people = 0;

    padit = {
        'previous': null,
        'age': null,
        'renal': null,
        'immuno': null,
        'procedure_type': null
    };

    selectedLabels = {
        'previous': null,
        'age': null,
        'renal': null,
        'immuno': null,
        'procedure_type': null
    }

    questionMap = {
        'previous': {
            'zero': {
                'label': '0',
                'score': 0
            },
            'one': {
                'label': '1',
                'score': 1
            },
            'two_or_more': {
                'label': '2 or more',
                'score': 3
            }
        },
        'age': {
            'less_than_60': {
                'label': 'Less than 60 years',
                'score': 2
            },
            '60_to_69': {
                'label': '60 - 69 years',
                'score': 1
            },
            '70_or_over': {
                'label': 'More than 70 years',
                'score': 0
            }
        },
        'renal': {
            'yes': {
                'label': 'Yes (estimated glomerular filtration rate < 30ml/min)',
                'score': 1
            },
            'no': {
                'label': 'No',
                'score': 0
            }
        },
        'immuno': {
            'yes': {
                'label': 'Yes (receiving therapy that suppresses resistance to infection or has a disease that is sufficiently advanced to suppress resistance to infection)',
                'score': 3
            },
            'no': {
                'label': 'No',
                'score': 0
            }
        },
        'procedure_type': {
            'pacemaker': {
                'label': 'Pacemaker: new pacemaker or pacemaker generator change',
                'score': 0
            },
            'icd': {
                'label': 'Implantable cardioverter defibrillator (ICD): new ICD or ICD generator change',
                'score': 2
            },
            'crt': {
                'label': 'Cardiac resynchronization therapy (CRT): new CRT pacemaker or defibrillator or CRT generator change',
                'score': 4
            },
            'revision': {
                'label': 'Revision / Upgrade: Pocket and/or lead revision and/or system upgrade, i.e. with adding new lead(s)',
                'score': 4
            }
        }
    }

    riskMap = {
        0: '0.36%',
        1: '0.32%',
        2: '0.39%',
        3: '0.65%',
        4: '0.81%',
        5: '1.06%',
        6: '1.64%',
        7: '2.82%',
        8: '2.83%',
        'over': '≥ 3.36% *'
    }

    peopleMap = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 1,
        6: 1,
        7: 2,
        8: 2,
        'over': 3
    }

    riskLabelMap = {
        0: 'low',
        1: 'low',
        2: 'low',
        3: 'low',
        4: 'low',
        5: 'moderate',
        6: 'moderate',
        7: 'high',
        8: 'high',
        'over': 'high'
    }

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);

        this.onChangeValue = this.onChangeValue.bind(this);

        this.state = this.initialState;
        this.state.padit = this.padit;
    }

    componentDidMount() {
        super.componentDidMount();
        this.calculateScore();
    }

    onChangeValue(e) {
        this.padit[e.target.name] = e.target.value;
        this.setState({ ...this.state, padit: this.padit })
        this.calculateScore();
    }

    calculateScore() {
        this.score = 0;
        for (let key in this.padit) {
            if (this.padit[key]) {
                this.score += this.questionMap[key][this.padit[key]]['score'];
            }
        }
        this.calculateRisk();
    }

    calculateRisk() {
        if (this.score > 8) {
            this.risk = this.riskMap['over'];
            this.riskLabel = this.riskLabelMap['over'];
            this.people = this.peopleMap['over'];
        } else {
            this.risk = this.riskMap[this.score];
            this.riskLabel = this.riskLabelMap[this.score];
            this.people = this.peopleMap[this.score];
        }
        this.setLabels();
    }

    setLabels() {
        for (let key in this.padit) {
            if (this.padit[key]) {
                this.selectedLabels[key] = this.questionMap[key][this.padit[key]]['label'];
            }
        }
    }

    isQuestionnaireComplete() {
        let questions = 0;
        const totalQuestions = Object.keys(this.padit).length;
        for (let key in this.padit) {
            if (this.padit[key]) {
                questions++;
            }
        }

        if (questions === totalQuestions) {
            return true;
        }

        return false;
    }

    getScoreMessage() {
        if (this.isQuestionnaireComplete()) {
            return (
                <div className="row">
                    <div>
                        Your score is <span className="font-bold">{this.score}</span>. You are at <span className="font-bold">{this.riskLabel}</span> risk for infection.
                        In the PADIT study patients with this score had a subsequent rate of hospitalization for device infection of <span className="font-bold">{this.risk}</span>.
                    </div>
                    <div className="grid-people">{this.createGrid(this.people, 100)}</div>
                    <div className="px-2 md:px-10 pt-0">{this.people} out of 100 patients were hospitalized for device infection.</div>
                </div>
            );
        }
    }

    getSampleSizeMessage() {
        if (this.isQuestionnaireComplete() && this.score >= 9) {
            return (
                <div className="mx-2 mt-2 mb-4">
                    * the sample size for scores ≥9 is too small to show score specific data
                </div>
            )
        }
    }

    createGrid = (red, total) => {
        let grid = [];

        for (let redCounter = 0; redCounter < red; redCounter++) {
            grid.push(<i className={"fas fa-male fa-" + this.figureColour}></i>);
        }

        for (let greyCounter = 0; greyCounter < (total - red); greyCounter++) {
            grid.push(<i className="fas fa-male"></i>);
        }

        return grid;
    }

    getCalculatorHtml() {
        return (
            <div className="padit-score-calculator">
                <h1>Understand the Risks of the Options</h1>

                <div className="row">
                    To find out your personal risk of device infection, please fill out the next questions about the 5 factors that can help predict
                    your risk of device infection. This is called the PADIT calculator. You may need to ask your health care provider(s) to clarify some
                    answers.
                </div>

                <div className="row">
                    <h5>
                        Prior procedure(s) on the same pocket - <i>How many cardiac device-related procedures have you had on or around your ICD incision?</i>
                    </h5>
                    <div className="radio-options">
                        <div className="radio">
                            <label>
                                <input type="radio" name="previous" value="zero" checked={this.padit.previous === "zero"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["previous"]["zero"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="previous" value="one" checked={this.padit.previous === "one"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["previous"]["one"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="previous" value="two_or_more" checked={this.padit.previous === "two_or_more"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["previous"]["two_or_more"]["label"]}</span>
                            </label>
                        </div>
                    </div>
                    <div className="radio-print">
                        {this.selectedLabels.previous}
                    </div>
                </div>
                <div className="row">
                    <h5>
                        Age - <i>How old are you?</i>
                    </h5>
                    <div className="radio-options">
                        <div className="radio">
                            <label>
                                <input type="radio" name="age" value="less_than_60" checked={this.padit.age === "less_than_60"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["age"]["less_than_60"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="age" value="60_to_69" checked={this.padit.age === "60_to_69"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["age"]["60_to_69"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="age" value="70_or_over" checked={this.padit.age === "70_or_over"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["age"]["70_or_over"]["label"]}</span>
                            </label>
                        </div>
                    </div>
                    <div className="radio-print">
                        {this.selectedLabels.age}
                    </div>
                </div>
                <div className="row">
                    <h5>
                        Depressed Renal Function - <i>Do you have chronic kidney disease (have you ever been told you had kidney problems)?</i>
                    </h5>
                    <div className="radio-options">
                        <div className="radio">
                            <label>
                                <input type="radio" name="renal" value="yes" checked={this.padit.renal === "yes"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["renal"]["yes"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="renal" value="no" checked={this.padit.renal === "no"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["renal"]["no"]["label"]}</span>
                            </label>
                        </div>
                    </div>
                    <div className="radio-print">
                        {this.selectedLabels.renal}
                    </div>
                </div>
                <div className="row">
                    <h5>
                        Immunocompromised - <i>Do you have a weak immune system?</i>
                    </h5>
                    <div className="radio-options">
                        <div className="radio">
                            <label>
                                <input type="radio" name="immuno" value="yes" checked={this.padit.immuno === "yes"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["immuno"]["yes"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="immuno" value="no" checked={this.padit.immuno === "no"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["immuno"]["no"]["label"]}</span>
                            </label>
                        </div>
                    </div>
                    <div className="radio-print">
                        {this.selectedLabels.immuno}
                    </div>
                </div>
                <div className="row">
                    <h5>
                        Type of Procedure - <i>What type of cardiac device and/or procedure are you considering?</i>
                    </h5>
                    <div className="radio-options">
                        <div className="radio">
                            <label>
                                <input type="radio" name="procedure_type" value="pacemaker" checked={this.padit.procedure_type === "pacemaker"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["procedure_type"]["pacemaker"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="procedure_type" value="icd" checked={this.padit.procedure_type === "icd"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["procedure_type"]["icd"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="procedure_type" value="crt" checked={this.padit.procedure_type === "crt"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["procedure_type"]["crt"]["label"]}</span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" name="procedure_type" value="revision" checked={this.padit.procedure_type === "revision"} onChange={this.onChangeValue} />
                                <span className="mx-2">{this.questionMap["procedure_type"]["revision"]["label"]}</span>
                            </label>
                        </div>
                    </div>
                    <div className="radio-print">
                        {this.selectedLabels.procedure_type}
                    </div>
                </div>

                { this.getScoreMessage() }
                { this.getSampleSizeMessage() }

                <div className="row">
                    <div className="text-xs py-4">
                        This risk calculator is named the PADIT calculator and was created based on the results of the PADIT study.  Reference: DH Birnie, AD Krahn et al.
                        Risk Factors for Infections Involving Cardiac Implanted Electronic Devices. J Am Coll Cardiol. 2019;74:2845-54.
                    </div>
                </div>


            </div>
        );
    }

    render() {
        return (
            <div>
                {this.getCalculatorHtml()}

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        )
    }
}

export default AbstractPaditScoreCalculator;