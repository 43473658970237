import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import Index from '../Index';
import Introduction from './Introduction';
import Choices from './Choices';
import KnowYourOptions from './KnowYourOptions';
import WhatMatters from './WhatMatters';
import PaditScoreCalculator from './PaditScoreCalculator';
import Benefits from './Benefits';
import Faq from './Faq';
import Additional from './Additional';

class Navigation extends Component {
    render() {
        return (
            <nav>
                <ul className="list-none leading-10">
                    <li key="intro">
                        <NavLink to={Introduction.url} exact>Introduction</NavLink>
                    </li>
                    <li>
                        <NavLink to={KnowYourOptions.url} exact>Know Your Options</NavLink>
                    </li>
                    <li>
                        <NavLink to={Benefits.url}>Understand the Benefits of the Options</NavLink>
                    </li>
                    <li>
                        <NavLink to={PaditScoreCalculator.url}>Understand the Risks of the Options</NavLink>
                    </li>
                    <li>
                        <NavLink to={WhatMatters.url}>Consider What Matters Most To You</NavLink>
                    </li>
                    <li>
                        <NavLink to={Choices.url}>What Else Do You Need to Make a Choice?</NavLink>
                    </li>
                    <li>
                        <NavLink to={Faq.url} exact>Frequently Asked Questions</NavLink>
                    </li>
                    <li>
                        <NavLink to={Additional.url} exact>Additional Information</NavLink>
                    </li>
                    <li>
                        <NavLink to={Index.url}>Return Home</NavLink>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Navigation;