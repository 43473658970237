import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import ScrollToTop from './partials/ScrollToTop'
import Header from './partials/Header'
import Index from './pages/Index';

import { default as ReplaceHeader } from './pages/Replace/Header'
import { default as ReplaceNavigation } from './pages/Replace/Navigation'
import { default as ReplaceIntroduction } from './pages/Replace/Introduction';
import { default as ReplaceIntroductionContinued } from './pages/Replace/IntroductionContinued';
import { default as ReplaceKnowYourOptions } from './pages/Replace/KnowYourOptions';
import { default as ReplaceBenefits } from './pages/Replace/Benefits';
import { default as ReplacePaditScoreCalculator } from './pages/Replace/PaditScoreCalculator';
import { default as ReplaceWhatMatters } from './pages/Replace/WhatMatters';
import { default as ReplaceChoices } from './pages/Replace/Choices';
import Faq from './pages/Replace/Faq';
import { default as ReplaceAdditional } from './pages/Replace/Additional';

import { default as ConsiderHeader } from './pages/Consider/Header'
import { default as ConsiderNavigation } from './pages/Consider/Navigation'
import { default as ConsiderIntroduction } from './pages/Consider/Introduction';
import { default as ConsiderIntroductionContinued } from './pages/Consider/IntroductionContinued';
import { default as ConsiderKnowYourOptions } from './pages/Consider/KnowYourOptions';
import { default as ConsiderBenefits } from './pages/Consider/Benefits';
import { default as ConsiderPaditScoreCalculator } from './pages/Consider/PaditScoreCalculator';
import { default as ConsiderWhatMatters } from './pages/Consider/WhatMatters';
import { default as ConsiderChoices } from './pages/Consider/Choices';
import { default as ConsiderAdditional } from './pages/Consider/Additional';

class App extends Component {

  mobileMenuClick() {
    const sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("hidden");
  }

  closeMobileMenu() {
    if (window.innerWidth < 1280) {
      const sidebar = document.querySelector(".sidebar");
      if (sidebar && !sidebar.classList.contains("hidden")) {
        sidebar.classList.add("hidden");
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop closeMobileMenu={this.closeMobileMenu.bind(this)}>
          <Header />
          <div>
            <Switch>
              <Route path='/replace'>
                <ReplaceHeader mobileMenuClick={this.mobileMenuClick.bind(this)} />
                <div className="replace relative min-h-screen xl:flex">
                  <div className="sidebar nav-wrapper xl:relative xl:translate-x-0 hidden xl:block">
                    <ReplaceNavigation />
                  </div>
                  <div className="content-wrapper">
                    <Switch>
                      <Route exact path={ReplaceIntroduction.url} component={ReplaceIntroduction} />
                      <Route exact path={ReplaceIntroductionContinued.url} component={ReplaceIntroductionContinued} />
                      <Route exact path={ReplaceAdditional.url} component={ReplaceAdditional} />
                      <Route exact path={ReplaceChoices.url} component={ReplaceChoices} />
                      <Route exact path={ReplaceKnowYourOptions.url} component={ReplaceKnowYourOptions} />
                      <Route exact path={ReplaceWhatMatters.url} component={ReplaceWhatMatters} />
                      <Route exact path={ReplacePaditScoreCalculator.url} component={ReplacePaditScoreCalculator} />
                      <Route exact path={ReplaceBenefits.url} component={ReplaceBenefits} />
                      <Route exact path={Faq.url} component={Faq} />
                    </Switch>
                  </div>
                </div>
              </Route>

              <Route path='/consider'>
                <ConsiderHeader mobileMenuClick={this.mobileMenuClick.bind(this)} />
                <div className="consider relative min-h-screen xl:flex">
                  <div className="sidebar nav-wrapper xl:relative xl:translate-x-0 hidden xl:block">
                    <ConsiderNavigation />
                  </div>
                  <div className="content-wrapper">
                    <Switch>
                      <Route exact path={ConsiderIntroduction.url} component={ConsiderIntroduction} />
                      <Route exact path={ConsiderIntroductionContinued.url} component={ConsiderIntroductionContinued} />
                      <Route exact path={ConsiderKnowYourOptions.url} component={ConsiderKnowYourOptions} />
                      <Route exact path={ConsiderBenefits.url} component={ConsiderBenefits} />
                      <Route exact path={ConsiderPaditScoreCalculator.url} component={ConsiderPaditScoreCalculator} />
                      <Route exact path={ConsiderWhatMatters.url} component={ConsiderWhatMatters} />
                      <Route exact path={ConsiderChoices.url} component={ConsiderChoices} />
                      <Route exact path={ConsiderAdditional.url} component={ConsiderAdditional} />
                    </Switch>
                  </div>
                </div>
              </Route>

              <Route component={Index} />
            </Switch>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;