import React from 'react';
import { withRouter } from 'react-router-dom';

import StorageComponent from '../StorageComponent';
import ButtonGroup from '../../partials/ButtonGroup';
import KnowYourOptions from './KnowYourOptions';
import PaditScoreCalculator from './PaditScoreCalculator';

class Benefits extends StorageComponent {
    static url = '/replace/benefits';

    localStorageKey = 'replace';

    replace_benefits = {
        'lower_risk': false,
        'peace_of_mind': false,
        'received_past': false,
        'paced_out': false
    };

    conditions = {
        'cancer': false,
        'congestive': false,
        'lung': false,
        'kidney': false,
        'stroke': false,
        'diabetes': false,
        'dementia': false
    };

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);

        this.onChangeAge = this.onChangeAge.bind(this);
        this.onChangeReason = this.onChangeReason.bind(this);
        this.onChangeShocks = this.onChangeShocks.bind(this);
        this.onChangeConditions = this.onChangeConditions.bind(this);
        this.onChangeBenefits = this.onChangeBenefits.bind(this);

        this.state = this.initialState;
        this.state.conditions = this.conditions;
        this.state.replace_benefits = this.replace_benefits;
    }

    handleBack() {
        this.props.history.push(KnowYourOptions.url);
    }

    handleNext() {
        this.props.history.push(PaditScoreCalculator.url);
    }

    onChangeAge(e) {
        this.setState({ ...this.state, age: e.target.value });
    }

    onChangeReason(e) {
        this.setState({ ...this.state, reason: e.target.value });
    }

    onChangeShocks(e) {
        this.setState({ ...this.state, shocks: e.target.value })
    }

    onChangeConditions(e) {
        this.conditions[e.target.value] = !this.conditions[e.target.value]
        this.setState({ ...this.state, conditions: this.conditions })
    }

    onChangeBenefits(e) {
        this.replace_benefits[e.target.value] = !this.replace_benefits[e.target.value]
        this.setState({ ...this.state, replace_benefits: this.replace_benefits })
    }

    createGrid = (blue, total) => {
        let grid = [];

        for (let blueCounter = 0; blueCounter < blue; blueCounter++) {
            grid.push(<i className="fas fa-male fa-blue"></i>);
        }

        for (let greyCounter = 0; greyCounter < (total - blue); greyCounter++) {
            grid.push(<i className="fas fa-male"></i>);
        }

        return grid;
    }

    render() {
        return (
            <div className="risks-and-benefits">
                <h1>Understand the Benefits of the Options</h1>

                <div className="row">
                    <h3>What are the benefits of replacing your ICD?</h3>

                    <div className="checkbox-group">
                        <label>
                            <input type="checkbox" name="replace_benefits" value="lower_risk" checked={this.replace_benefits.lower_risk} onChange={this.onChangeBenefits} />
                            <span className="mx-2">The ICD may lower your risk of a sudden cardiac death from a fast dangerous heart rhythm.</span>
                        </label>
                    </div>
                    <div className="checkbox-group">
                        <label>
                            <input type="checkbox" name="replace_benefits" value="peace_of_mind" checked={this.replace_benefits.peace_of_mind} onChange={this.onChangeBenefits} />
                            <span className="mx-2">The ICD may give you peace of mind that if you have a fast dangerous heart rhythm it could be corrected.</span>
                        </label>
                    </div>
                    <div className="checkbox-group">
                        <label>
                            <input type="checkbox" name="replace_benefits" value="received_past" checked={this.replace_benefits.received_past} onChange={this.onChangeBenefits} />
                            <span className="mx-2">You have received as shock in the past.</span>
                        </label>
                    </div>
                    <div className="checkbox-group">
                        <label>
                            <input type="checkbox" name="replace_benefits" value="paced_out" checked={this.replace_benefits.paced_out} onChange={this.onChangeBenefits} />
                            <span className="mx-2">You have been paced out of a dangerous rhythm before receiving a shock.</span>
                        </label>
                    </div>
                </div>

                {/* <div className="row">
                    Replacing an ICD can also impact survival, and these numbers will vary depending on your <span className="strong">overall health status, </span>
                    including your sex, age, heart condition, or other health issues (e.g. kidney disease, lung disease, prior stroke, diabetes), and whether you
                    have had shocks for fast dangerous heart rhythms.
                </div> */}

                <div className="row print:hidden">
                    The true benefit for you will vary depending on your overall health status, including your sex, age, heart condition, or other health
                    issues (e.g. kidney disease, lung disease, prior stroke, diabetes), and whether you have had shocks for fast dangerous heart rhythms
                    in the past. Please answer the next questions and share your responses with members of your health care team.
                </div>

                <div className="row">
                    <h3>To best calculate the life-saving benefit of the ICD for you, it is helpful to know:</h3>
                    <div className="question-form question-form-3">
                        <div className="text short">
                            1. Your age (years)
                        </div>
                        <div className="radio">
                            <div className="checkbox-group">
                                <label>
                                    <input className="number" type="number" name="age" min="19" max="120" autoFocus value={this.state.age} onChange={this.onChangeAge}/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 question-form question-form-3">
                        <div className="text short">
                            2. Reason for which you have an ICD
                        </div>
                        <div className="radio">
                            <div className="checkbox-group">
                                <label>
                                    <input type="radio" name="reason" value="primary" checked={this.state.reason === "primary"} onChange={this.onChangeReason}/>
                                    <span className="mx-2">Primary prevention</span>
                                </label>
                                <label>
                                    <input type="radio" name="reason" value="secondary" checked={this.state.reason === "secondary"} onChange={this.onChangeReason}/>
                                    <span className="mx-2">Secondary prevention</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row print:hidden">
                        {/* <h5 className="mb-0">What is the Difference Between Primary and Secondary prevention?</h5> */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            <div className="question-box question-box-short">
                                <hr />
                                <div className="heading">
                                    Primary Prevention
                                </div>
                                <div className="content">
                                    If you have not had a fast dangerous heart rhythm, but your heart condition or family history makes you at risk for them,
                                you have an ICD for <i>primary prevention</i>.
                                </div>
                                <hr />
                            </div>

                            <div className="question-box question-box-short">
                                <hr />
                                <div className="heading">
                                    Secondary Prevention
                                </div>
                                <div className="content">
                                    If you have had a fast dangerous heart rhythm such as ventricular tachycardia or ventricular fibrillation, you have an
                                ICD for <i>secondary prevention</i>.
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 question-form question-form-3">
                        <div className="text">
                            3. Whether you have had shock(s) for fast dangerous heart rhythms
                        </div>
                        <div className="radio short">
                            <div className="checkbox-group">
                                <label>
                                    <input type="radio" name="shocks" value="yes" checked={this.state.shocks === "yes"} onChange={this.onChangeShocks}/>
                                    <span className="mx-2">Yes</span>
                                </label>
                                <label>
                                    <input type="radio" name="shocks" value="no" checked={this.state.shocks === "no"} onChange={this.onChangeShocks} />
                                    <span className="mx-2">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text mb-2">
                            4. Whether you have any of the following health conditions (check all boxes
                            that are relevant to you)
                        </div>
                        <div className="grid grid-cols-4">
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="cancer" checked={this.conditions.cancer} onChange={this.onChangeConditions} />
                                    <span className="mx-2">Cancer</span>
                                </label>
                            </div>
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="congestive" checked={this.conditions.congestive} onChange={this.onChangeConditions}/>
                                    <span className="mx-2">Congestive Heart Failure</span>
                                </label>
                            </div>
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="lung" checked={this.conditions.lung} onChange={this.onChangeConditions}/>
                                    <span className="mx-2">Lung Disease</span>
                                </label>
                            </div>
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="kidney" checked={this.conditions.kidney} onChange={this.onChangeConditions}/>
                                    <span className="mx-2">Kidney Disease</span>
                                </label>
                            </div>
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="stroke" checked={this.conditions.stroke} onChange={this.onChangeConditions}/>
                                    <span className="mx-2">Prior Stroke</span>
                                </label>
                            </div>
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="diabetes" checked={this.conditions.diabetes} onChange={this.onChangeConditions}/>
                                    <span className="mx-2">Diabetes</span>
                                </label>
                            </div>
                            <div className="checkbox-group">
                                <label>
                                    <input type="checkbox" name="conditions" value="dementia" checked={this.conditions.dementia} onChange={this.onChangeConditions}/>
                                    <span className="mx-2">Dementia</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <ButtonGroup handleBack={this.handleBack} handleNext={this.handleNext} />
            </div>
        );
    }
}

export default withRouter(Benefits);