import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../scss/additional.scss';
// import ButtonGroup from '../../partials/ButtonGroup';
import Faq from './Faq';

import logoUOttawaImg from '../../images/logos/uottawa.png';
import logoUohiImg from '../../images/logos/uohi.png';

class Additional extends Component {
    static url = '/replace/info';

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);
    }

    handleBack() {
        this.props.history.push(Faq.url);
    }

    render() {
        return (
            <div className="info">
                <h1>Additional Information</h1>

                <div className="row">
                    Canadian website with ICD information for patients and families: <a href="https://www.ottawaheart.ca/document/icds-implantable-cardioverter-defibrillators" target="_blank" rel="noreferrer">https://www.ottawaheart.ca/document/icds-implantable-cardioverter-defibrillators</a>
                </div>
                <div className="row my-4">
                    Copyright &copy; 2022.
                </div>
                <div className="row">
                    Primary content editor: Krystina B. Lewis RN PhD University of Ottawa / University of Ottawa Heart Insititute.
                </div>
                <div className="row">
                    This patient decision aid was developed by a team of researchers, including patients, family members, and clinicians
                    with expertise in shared decision-making and ICDs. For more information, please contact K. Lewis directly
                    at <a href="mailto:Krystina.Lewis@uottawa.ca">Krystina.Lewis@uottawa.ca</a>.
                </div>
                <div className="mt-8">
                    Original version funded by Canadian Institutes of Health Research.
                </div>
                <div className="row">
                    Current version funded in part by Canadian Cardiovascular Society and CANet.
                </div>
                <div className="row">
                    None of the authors stand to gain or lose from the choices patients make after using this decision aid.
                </div>

                <h1 className="mt-12">References</h1>

                <div className="row">
                Birnie, DH, Wang, J, Alings M, Philippon, F, Parkash, R, Manlucu, J. et al. Risk Factors for Infections Involving Cardiac Implanted
                Electronic Devices. J Am Coll Cardiol. 2019;74:2845-54. doi: 10.1016/j.jacc.2019.09.060.
                </div>
                <div className="row">
                Dunbar SB, Dougherty CM, Sears SF, Carroll DL, Goldstein NE, Mark DB, McDaniel G, Pressler SJ, Schron E, Wang P et al: Implantable
                cardioverter defibrillators and their families: A scientific statement from educational and psychological interventions to improve
                outcomes for recipients of the American Heart Association. Circulation 2012, 126:2146-2172. https://doi.org/10.1161/CIR.0b013e31825d59fd
                </div>
                <div className="row">
                Healey JS, Hallstrom AP, Kuck KH, Nair G, Schron EP, Roberts RS, Morillo CA, et al. Role of the implantable defibrillator among elderly
                patients with a history of life-threatening ventricular arrhythmias. Eur Heart J 2007;28:1746-1749. doi: 10.1093/eurheartj/ehl438.
                </div>
                <div className="row">
                Hess PL, Al-Khatib SM, Han JY, Edwards R, Bardy GH, Bigger JT, Buxton A, et al. Survival benefit of the primary prevention implantable
                cardioverter-defibrillator among older patients: does age matter? An analysis of pooled data from 5 clinical trials. Circ Cardiovasc
                Qual Outcomes 2015;8:179-186. doi: 10.1161/CIRCOUTCOMES.114.001306
                </div>
                <div className="row">
                Lampert R, Hayes DL, Annas GJ, Farley MA, Goldstein NE, Hamilton RM, Kay GN, Kramer DB, Mueller PS, Padeletti L et al: HRS expert
                consensus statement on the management of cardiovascular implantable electronic devices (CIEDs) in patients nearing end of life or
                requesting withdrawal of therapy. Heart Rhythm 2010, 7(7):1008-1026. doi: 10.1016/j.hrthm.2010.04.033.
                </div>
                <div className="row">
                Lewis KB, Stacey D, Matlock D. Making decisions about implantable cardioverter-defibrillators from implantation to end of life: An
                integrative review of patients’ perspectives. The Patient: Patient-Centered Outcomes Research 2014, 7(3):243-60. doi: 10.1007/s40271-014-0055-2.
                </div>
                <div className="row">
                Lewis KB, Stacey D, Carroll SL, Boland L, Sikora L, Birnie D. Estimating the risks and benefits of implantable cardioverter-defibrillator
                generator replacement: A systematic review. Pacing and Clinical Electrophysiology 2016, 39(7), 709-722. doi: 10.1111/pace.12850.
                </div>
                <div className="row">
                Yung D, Birnie D, Dorian P, Healey JS, Simpson CS, Crystal E, Krahn AD, et al. Survival after implantable cardioverter-defibrillator
                implantation in the elderly. Circulation. 2013;127:2383-2392. https://doi.org/10.1161/CIRCULATIONAHA.113.001442
                </div>

                {/* <ButtonGroup handleBack={this.handleBack} hideNext={true} /> */}

                <div className="row flex items-center justify-center grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-1 lg:pt-12">
                    <div className="image logo-image">
                        <img src={logoUOttawaImg} className="image-sm" alt="University of Ottawa" />
                    </div>
                    <div className="image logo-image">
                        <img src={logoUohiImg} className="image-sm" alt="University of Ottawa Heart Institute" />
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(Additional);