import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import consideringImg from '../images/consider/landing.png';
import replacingImg from '../images/replace/landing.png';
import logoCihrImg from '../images/logos/cihr.png';
import logoCanetImg from '../images/logos/canet.png';
import logoCcsImg from '../images/logos/ccs.png';
import logoUOttawaImg from '../images/logos/uottawa.png';
import logoUohiImg from '../images/logos/uohi.png';
import logoMcMasterImg from '../images/logos/mcmaster.png';
import Button from '../partials/Button';
import { default as ReplaceIntroduction } from './Replace/Introduction';
import { default as ConsiderIntroduction } from './Consider/Introduction';

class Index extends Component {
    static url = '/';

    constructor(props) {
        super(props)

        this.handleConsiderClick = this.handleConsiderClick.bind(this);
        this.handleReplaceClick = this.handleReplaceClick.bind(this);

        // reset user entered values when loading landing page
        window.localStorage.setItem('replace', JSON.stringify({}));
        window.localStorage.setItem('consider', JSON.stringify({}));
    }

    handleConsiderClick() {
        this.props.history.push(ConsiderIntroduction.url);
    }

    handleReplaceClick() {
        this.props.history.push(ReplaceIntroduction.url);
    }

    render() {
        return (
            <div className="w-full py-2 md:py-8 md:px-16 bg-white">
                <div className="mx-auto w-72 md:w-80">
                    <h1 className="py-4 mb-2 no-ul text-white uppercase text-center bg-black">
                        Patient Decision Aids
                    </h1>
                </div>
                <h2 className="mb-4 lg:mx-4 xl:mx-16 2xl:mx-24 no-ul text-center font-normal">
                    Implantable Cardioverter-Defibrillator Decisions
                </h2>
                <div className="row grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="consider">
                        <h2 className="text-center">
                            Initial ICD Implantation
                        </h2>
                        <h4 className="text-center mt-1">
                            For patients who do not have an ICD
                        </h4>
                        <div className="image">
                            <NavLink to={ConsiderIntroduction.url}>
                                <img src={consideringImg} className="image-md" alt="Considering an ICD" />
                            </NavLink>
                        </div>
                        <Button text="Begin" handleClick={this.handleConsiderClick} />
                    </div>
                    <div className="replace">
                        <h2 className="text-center">
                            ICD Replacement
                        </h2>
                        <h4 className="text-center mt-1">
                            For patients who have an ICD
                        </h4>
                        <div className="image">
                            <NavLink to={ReplaceIntroduction.url}>
                                <img src={replacingImg} className="image-md" alt="Replacing an ICD" />
                            </NavLink>
                        </div>
                        <Button text="Begin" handleClick={this.handleReplaceClick} />
                    </div>
                </div>
                <div className="row px-4 md:mx-auto text-center pt-8 w-full lg:w-2/3 text-sm">
                    The information provided in these decision aids is designed to help you make a decision with
                    your health care provider(s). It is not meant to replace their advice.
                </div>
                <div className="row flex items-center justify-center grid grid-cols-1 lg:grid-cols-5 gap-4 lg:gap-1 lg:pt-12">
                    <div className="lg:col-span-5 mx-auto text-center font-bold text-sm pt-4 lg:pt-0 pb-2">
                        Funding provided by:
                    </div>
                    <div className="lg:col-start-2 image logo-image">
                        <img src={logoCihrImg} className="image-sm" alt="CIHR/IRSC" />
                    </div>
                    <div className="image logo-image">
                        <img src={logoCanetImg} className="image-sm" alt="CANet" />
                    </div>
                    <div className="lg:col-start-4 image logo-image">
                        <img src={logoCcsImg} className="image-sm" alt="Canadian Cardiovascular Society" />
                    </div>
                    <div className="lg:col-span-5 mx-auto text-center font-bold text-sm pb-2">
                        Site Partners:
                    </div>
                    <div className="lg:col-start-2 image logo-image">
                        <img src={logoUOttawaImg} className="image-sm" alt="University of Ottawa" />
                    </div>
                    <div className="image logo-image">
                        <img src={logoUohiImg} className="image-sm" alt="University of Ottawa Heart Institute" />
                    </div>
                    <div className="lg:col-start-4 image logo-image">
                        <img src={logoMcMasterImg} className="image-sm" alt="McMaster University" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Index);